import CollectionClientAPI from '@src/api/collection/CollectionClientAPI';
import { IStamp, IStampBackEnd } from '@src/api/stamp/types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { isAdmin } from "@src/contexts";
import { RenderIf } from "@ui/components";

// Interface para representar uma imagem
// interface Image {
//   id: number;
//   src: string;
// }

const Modal = styled.div`
  position: fixed;
  left:22vw;
  top:13vh;
  width:60vw;
  height:80vh;
  background-color:white;
  display:block;
  z-index: 1001;
  border:5px inset black;
  border-radius:16px;
  padding:20px;
`;

const ImageContainer = styled.div`
  width:50vw;
  height:50vh;
  margin-left:5%;
  overflow-y:auto;
  border-radius:16px;
  display:block;
`;

const Image = styled.img<{opacity: number}>`
  width: 40%;
  height: 60%;
  object-fit: cover;
  margin: 5%;
  border: 1px solid black;
  border-radius:12px;
  box-shadow: 5px 10px:
  cursor: pointer;
  opacity: ${(props) => props.opacity};
`;

const CreateButton = styled.button `
  background-color: white;
  color: black;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  margin-left:1vw;
  width:10vw;

  &:hover {
    background-color: #1A1A1A;
    color: white;
  }
`

const SaveButton = styled.button `
  background-color: white;
  color: black;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  margin-left:38%;
  margin-right:5%;
  margin-top:4%;

  &:hover {
    background-color: #1A1A1A;
    color: white;
  }
`

const CloseButton = styled.button `
  background-color: white;
  color: red;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border: 1px solid red;
  border-radius: 4px;
  cursor: pointer;
  margin-left:auto;
  margin-right:auto;
  &:hover {
    background-color: red;
    color: white;
  }

  @media(max-width:800px){
    font-size:13px;
    width:100%;
    margin-top:5vw;

  }
`

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 5vw;
  color: #1A1A1A;
  margin:5%;
`;

interface IAllStamps {
  collectionId: number,
  allStamps: IStamp[],
  collectionStamps: IStampBackEnd[]
}

const ModalIncludeStamps = (props: IAllStamps) => {

  const stampValues:number[] = []

  const [showModal, setShowModal] = useState(false)

  const handleOpenModal = () => {
    console.log(props)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  if(props.collectionStamps.length > 0){    
    props.collectionStamps.map(collectionStamp => (
      stampValues.push(collectionStamp.id)
    ))
  } 

  const [selectedImages, setSelectedImages] = useState<number[]>(stampValues);
  
  const handleSelectImage = (imageId: number) => {
    const newSelectedImages = [...selectedImages];
  
    if (newSelectedImages.includes(imageId)) {
      newSelectedImages.splice(newSelectedImages.indexOf(imageId), 1);
    } else {
      newSelectedImages.push(imageId);
    }
  
    setSelectedImages(newSelectedImages);
  }
  
  const handleSaveImages = () => {
    const collectionClientAPI = new CollectionClientAPI();
    const selectedImageIds = selectedImages;

    collectionClientAPI.attachStamps({
      collection_id: props.collectionId.toString(),
      stamps: JSON.stringify(selectedImageIds)
    }).then(() => window.location.reload())
  }

  return (
    <>
      <RenderIf condition={isAdmin()}>
        <CreateButton 
          onClick={() => handleOpenModal()}
        >Vincular Tecidos
        </CreateButton>
      </RenderIf>

      {showModal && (
        <Modal>

          <Title>Selecione as Imagens</Title>
       
          <ImageContainer>
            {props.allStamps.map(stamp => (
              <Image
                key={stamp.id}
                src={process.env.PUBLIC_URL + stamp.imageStamp}
                onClick={() => handleSelectImage(stamp.id)}
                opacity={selectedImages.includes(stamp.id) ? 0.5: 1.0}
              />
            ))}
          </ImageContainer>


           <SaveButton onClick={handleSaveImages}>Salvar</SaveButton>
           <CloseButton onClick={handleCloseModal}>Fechar</CloseButton> 
          
        </Modal>
      )}
    </>
  )
}

export { ModalIncludeStamps }
export default ModalIncludeStamps
