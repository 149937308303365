import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { HomeClientAPI } from '@api/home';
import { CarrouselEdit, CollectionEdit } from './components';
import { IHome } from '@api/home/types';
import { StampsBackgroundImages } from '../stamps/components';
import { Link } from 'react-router-dom';
import { ButtomBack } from '../blog';
import { FaAngleLeft } from 'react-icons/fa';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 16px;
`;

const DivCarouselEdit = styled.div`
  background-color: #eee;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid black;
`;

const DivCollectionEdit = styled.div`
  background-color: #eee;
  padding: 16px;
  border: 1px solid black;
`;

const homeClientAPI = new HomeClientAPI()

const AdminArea = () => {
  const [home, setHome] = useState<IHome>()

  useEffect(() => {
    homeClientAPI.getHome()
      .then((response: IHome) => {
        setHome(response)
      })
  }, [])

  return (
    <>
      <StampsBackgroundImages />

      <div style={{ marginLeft: 30, marginTop: 30 }}>
        <Link to={'/'}>
          <ButtomBack>
            <FaAngleLeft style={{ color: '#252525', marginBottom: 6, marginLeft: 8 }} />
          </ButtomBack>
        </Link>
      </div>

      <Container style={{ paddingTop: 50 }}>
        <Title>Edição das fotos no carrosel inicial.</Title>

        <DivCarouselEdit>
          {
          home && <CarrouselEdit carrouselImages={home.carousel.carousel_image} />
          }
        </DivCarouselEdit>

        <Title>Seleção da coleção que será exibida na tela inicial.</Title>

        <DivCollectionEdit>
          <CollectionEdit />
        </DivCollectionEdit>
      </Container>
    </>
  );
};

export { AdminArea };
export default AdminArea;
